import React from 'react';
import { useTranslation } from 'react-i18next';

import SearchIconDetails from 'assets/svg/searchIconDetails.svg';

import { Fade } from '@material-ui/core';

import { TableContainer } from 'app/WMS/styles/styles';

import { InventoryData } from 'utils/interfaces/Log';

import { Table } from 'components/Tools/Table';

import { IInventoryTableProps } from '../../types';

const InventoryTable: React.FC<IInventoryTableProps> = ({
  rows,
  handleOpenDetailsModal,
}) => {
  const { t } = useTranslation();

  return (
    <Fade in timeout={1000} unmountOnExit>
      <TableContainer>
        <Table<InventoryData>
          columns={[
            {
              title: String(t('screens.reportsStock.address')),
              orderable: true,
              type: 'string',
              props: ['endereco'],
              display: 'notEmpty',
              cssTitle: {
                width: '1%',
              },
              cssProps: {
                width: '1%',
              },
            },
            {
              title: String(t('screens.commom.pallet')),
              type: 'string',
              orderable: true,
              display: 'notEmpty',
              props: ['descricaoPalete'],
              cssTitle: {
                width: '1%',
              },
              cssProps: {
                width: '1%',
              },
            },
            {
              title: String(t('screens.commom.box')),
              orderable: true,
              type: 'string',
              props: ['descricaoCaixa'],
              display: 'notEmpty',
              cssTitle: {
                width: '1%',
              },
              cssProps: {
                width: '1%',
              },
            },
            {
              title: String(t('screens.commom.product')),
              type: 'string',
              props: ['produto'],
              display: 'notEmpty',

              orderable: true,
              cssTitle: {
                width: '1%',
              },
              cssProps: {
                width: '1%',
              },
            },
            {
              title: String(t('screens.commom.actions')),
              type: 'string',
              props: [''],
              cssTitle: {
                width: '1%',
              },
              cssProps: {
                width: '0.5%',
              },
              renderItem: row => (
                <div className="row-actions-button-detail">
                  <button
                    type="button"
                    className="action"
                    onClick={() => handleOpenDetailsModal(row)}
                  >
                    <img
                      src={SearchIconDetails}
                      alt="Detalhes"
                      style={{ width: '30px' }}
                    />
                    <p className="hover-item">{t('screens.commom.details')}</p>
                  </button>
                </div>
              ),
            },
            {
              title: String(t('screens.commom.size')),
              orderable: true,
              type: 'string',
              props: ['tamanho'],
              display: 'notEmpty',
              cssTitle: {
                width: '1%',
              },
              cssProps: {
                width: '1%',
              },
            },
            {
              title: String(t('screens.commom.color')),
              orderable: true,
              type: 'string',
              props: ['cor'],
              display: 'notEmpty',
              cssTitle: {
                width: '1%',
              },
              cssProps: {
                width: '1%',
              },
            },
            {
              title: String(t('screens.reportsStock.descriptionProduct')),
              orderable: true,
              type: 'string',
              props: ['descricaoProduto'],
              display: 'notEmpty',
              cssTitle: {
                width: '1%',
              },
              cssProps: {
                width: '1%',
              },
            },
            {
              title: String(t('screens.reportsStock.codeRequest')),
              orderable: true,
              type: 'string',
              props: ['codigoPedido'],
              display: 'notEmpty',
              cssTitle: {
                width: '1%',
              },
              cssProps: {
                width: '1%',
              },
            },
            {
              title: String(t('screens.commom.stock')),
              type: 'string',
              orderable: true,
              props: ['quantidade'],
              display: 'notEmpty',
              cssTitle: {
                width: '1%',
              },
              cssProps: {
                width: '1%',
              },
            },
            {
              title: String(t('screens.reportsStock.destinationStore')),
              type: 'string',
              orderable: true,
              props: ['lojaDestino'],
              display: 'notEmpty',
              cssTitle: {
                width: '1%',
              },
              cssProps: {
                width: '1%',
              },
            },
          ]}
          defaultNumberOfRows={10}
          rows={rows}
          exportList
        />
      </TableContainer>
    </Fade>
  );
};

export default InventoryTable;
